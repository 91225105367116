const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, " +
  "ah3ahah_pc, ah3r_pct, ah3h_pct, ah3g_pct, ah3e_pct, ah3ffood_p, ah3pubs_pc, " +
  "ah3gamb_pc, ah3tob_pct, ah3gp_pct, ah3dent_pc, ah3phar_pc, ah3hosp_pc, " +
  "ah3leis_pc, ah3gpas_pc, ah3blue_pc, ah3no2_pct, ah3pm10_pc, ah3so2_pct";

const options = [
  {
    id: 0,
    name: "Decile",
    conversion: function(num) {
      return Math.ceil(num / 10);
    }
  },
  {
    id: 1,
    name: "Quartile",
    conversion: function(num) {
      return Math.ceil(num / 4);
    }
  },
  {
    id: 2,
    name: "Percentile",
    conversion: function(num) {
      return num;
    }
  }
];

const legendStops = [
  {
    id: 0,
    stops: [
      { value: 0, colour: "#4575B4", name: "Best performing decile" },
      { value: 11, colour: "#7397B6", name: "2nd" },
      { value: 21, colour: "#A2BAB9", name: "3rd" },
      { value: 31, colour: "#D0DCBC", name: "4th" },
      { value: 41, colour: "#FFFFBF", name: "5th" },
      { value: 51, colour: "#FCE1A6", name: "6th" },
      { value: 61, colour: "#FAC48D", name: "7th" },
      { value: 71, colour: "#F8A774", name: "8th" },
      { value: 81, colour: "#F68A5B", name: "9th" },
      { value: 91, colour: "#F46D43", name: "Worst performing decile" }
    ]
  },
  {
    id: 0,
    stops: [
      { value: 0, colour: "#4575B4", name: "Best performing quartile" },
      { value: 25, colour: "#A2BAB9", name: "2nd" },
      { value: 50, colour: "#FCE1A6", name: "3rd" },
      { value: 75, colour: "#F46D43", name: "Worst performing quartile" }
    ]
  },
  {
    id: 3,
    stops: [
      { value: 0, colour: "#4575B4", name: "Best Performing Percentiles" },
      { value: 11, colour: "#7397B6", name: "11 - 20 " },
      { value: 21, colour: "#A2BAB9", name: "21 - 30 " },
      { value: 31, colour: "#D0DCBC", name: "31 - 40 " },
      { value: 41, colour: "#FFFFBF", name: "41 - 50 " },
      { value: 51, colour: "#FCE1A6", name: "51 - 60 " },
      { value: 61, colour: "#FAC48D", name: "61 - 70 " },
      { value: 71, colour: "#F8A774", name: "71 - 80 " },
      { value: 81, colour: "#F68A5B", name: "81 - 90 " },
      { value: 91, colour: "#F46D43", name: "Worst Performing Percentiles" }
    ]
  }
];

export const AHAH = {
  title: "Access to Healthy Assets & Hazards",
  description:
    "A multi-dimensional index developed by the CDRC for Great Britain measuring how ‘healthy’ neighbourhoods are.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/access-healthy-assets-hazards-ahah",
  storyLinks: [
    {
      link: "https://data.cdrc.ac.uk/stories/usingahah",
      title:
        "Using Access to Health Assets and Hazards (AHAH) to understand how 'healthy' places are"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk where ah3ahah_pc is not null",
  buildingSql:
    "SELECT " + cols + " FROM lsoa11_uk_buildings where ah3ahah_pc is not null",
  detailUrbanSql:
    "SELECT " +
    cols +
    " FROM lsoa11_uk_detail_urban where ah3ahah_pc is not null",
  urbanSql:
    "SELECT " + cols + " FROM lsoa11_uk_urban where ah3ahah_pc is not null",
  defaultLayers: "1111000",
  extent: 0,
  popup: true,
  popupType: "custom",
  popupFeatureConfig: {
    sections: [
      {
        id: 0,
        title: null,
        headers: ["Field", "Value"],
        rows: [
          {
            name: "AHAH",
            field: "ah3ahah_pc",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Retail Domain",
            field: "ah3r_pct",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Health Domain",
            field: "ah3h_pct",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Blue/Green Space Domain",
            field: "ah3g_pct",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Air Quality Domain",
            field: "ah3e_pct",
            format: "number",
            decimalPlaces: 0
          }
        ]
      }
    ]
  },
  popupGeographyConfig: {
    sections: [
      {
        id: 0,
        title: null,
        headers: ["AHAH", "Percentage By Count"],
        rows: [
          {
            id: 1,
            name: "Best performing Decile",
            field: "ah3decile_1",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 2,
            name: "2nd",
            field: "ah3decile_2",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 3,
            name: "3rd",
            field: "ah3decile_3",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 4,
            name: "4th",
            field: "ah3decile_4",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 5,
            name: "5th",
            field: "ah3decile_5",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 6,
            name: "6th",
            field: "ah3decile_6",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 7,
            name: "7th",
            field: "ah3decile_7",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 8,
            name: "8th",
            field: "ah3decile_8",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 9,
            name: "9th",
            field: "ah3decile_9",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 10,
            name: "Worst performing Decile",
            field: "ah3decile_10",
            format: "percent",
            decimalPlaces: 0
          }
        ]
      }
    ]
  },
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    sum(case when d.ah3ahah_pc <=10 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_1,
    sum(case when d.ah3ahah_pc <=20 and d.ah3ahah_pc >10 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_2,
    sum(case when d.ah3ahah_pc <=30 and d.ah3ahah_pc >20 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_3,
    sum(case when d.ah3ahah_pc <=40 and d.ah3ahah_pc >30 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_4,
    sum(case when d.ah3ahah_pc <=50 and d.ah3ahah_pc >40 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_5,
    sum(case when d.ah3ahah_pc <=60 and d.ah3ahah_pc >50 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_6,
    sum(case when d.ah3ahah_pc <=70 and d.ah3ahah_pc >60 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_7,
    sum(case when d.ah3ahah_pc <=80 and d.ah3ahah_pc >70 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_8,
    sum(case when d.ah3ahah_pc <=90 and d.ah3ahah_pc >80 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_9,
    sum(case when d.ah3ahah_pc >90 then 1 else 0 end) / cast(count(*)as numeric) as ah3decile_10
  FROM oliverobrien.lsoa11_uk d

  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "MetricOnly",
  metrics: [
    {
      id: 0,
      name: "AHAH",
      field: "ah3ahah_pc",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [1, 2, 3, 4]
    },
    {
      id: 1,
      name: "Retail Domain",
      field: "ah3r_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [5, 6, 7, 8, 9]
    },
    {
      id: 2,
      name: "Health Domain",
      field: "ah3h_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [10, 11, 12, 13, 14]
    },
    {
      id: 3,
      name: "Blue/Green Space Domain",
      field: "ah3g_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [15, 16]
    },
    {
      id: 4,
      name: "Air Quality Domain",
      field: "ah3e_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [17, 18, 19]
    },
    {
      id: 5,
      name: "Fast Food Accessibility",
      field: "ah3ffood_p",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 6,
      name: "Pub/bar/nightclub Accessibility",
      field: "ah3pubs_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 7,
      name: "Gambling Outlet Accessibility",
      field: "ah3gamb_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 8,
      name: "Tobacconist Accessibility",
      field: "ah3tob_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 9,
      name: "GP practice Accessibility",
      field: "ah3gp_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 10,
      name: "Dentist Accessibility",
      field: "ah3dent_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 11,
      name: "Pharmacy Accessibility",
      field: "ah3phar_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 12,
      name: "Hospital Accessibility",
      field: "ah3hosp_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 13,
      name: "Leisure Service Accessibility",
      field: "ah3leis_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 14,
      name: "Green Space (Passive) Accessibility",
      field: "ah3gpas_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 15,
      name: "Blue Space Accessibility",
      field: "ah3blue_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 16,
      name: "Nitrogen Dioxide (NO&#8322;) Level",
      field: "ah3no2_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 17,
      name: "Particulate Matter (PM10) Level",
      field: "ah3pm10_pc",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 18,
      name: "Sulphur Dioxide (SO&#8322;) Level",
      field: "ah3so2_pct",
      legendOptions: options,
      legendStops: legendStops
    }
  ]
};
