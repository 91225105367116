function roadLayers(sector) {
  return [
    {
      name: "motorway" + sector,
      popup: false,
      source: sector,
      minzoom: 8,
      group: "details",
      filter: [
        "all",
        ["in", "class", "Motorway", "Freeway", "Tollway", "motorway", "trunk"]
      ],
      type: "line",
      layout: { "line-cap": "round" },
      paint: {
        "line-color": "#444",
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          1,
          14,
          12
        ],
        "line-blur": 1
      }
    },
    {
      name: "aroad" + sector,
      popup: false,
      source: sector,
      group: "details",
      minzoom: 9,
      filter: ["all", ["in", "class", "A Road", "primary"]],
      type: "line",
      layout: {
        "line-cap": "round"
      },
      paint: {
        "line-color": "#444",
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          8,
          1.5,
          14,
          9
        ],
        "line-blur": 1
      }
    },
    {
      name: "broad" + sector,
      popup: false,
      source: sector,
      group: "details",
      minzoom: 10,
      filter: ["all", ["in", "class", "B Road", "Paved", "secondary"]],
      type: "line",
      layout: { "line-cap": "round" },
      paint: {
        "line-color": "#444",
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          11,
          1,
          14,
          6
        ]
      }
    },
    {
      name: "street" + sector,
      popup: false,
      source: sector,
      group: "details",
      minzoom: 10.5,
      filter: [
        "all",
        [
          "in",
          "class",
          "Not Classified",
          "Unknown",
          "Classified Unnumbered",
          "Unclassified",
          "Other Paved",
          "tertiary"
        ]
      ],
      type: "line",
      layout: { "line-cap": "round" },
      paint: {
        "line-color": "#444",
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          11,
          0.5,
          14,
          4.5
        ]
      }
    },
    {
      name: "aroad" + sector + "labels",
      type: "symbol",
      source: sector,
      group: "details",
      minzoom: 13,
      filter: ["all", ["in", "class", "A Road", "primary"]],
      layout: {
        "symbol-placement": "line",
        "text-field": ["upcase", ["coalesce", ["get", "name1"], ""]],
        "text-letter-spacing": 0.25,
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          13,
          10,
          16,
          18
        ]
      },
      paint: {
        "text-color": "hsla(0, 0%, 100%, 1)",
        "text-halo-color": "hsla(0, 0%, 0%, 0.72)",
        "text-halo-width": 2
      }
    },
    {
      name: "broad" + sector + "labels",
      type: "symbol",
      source: sector,
      group: "details",
      minzoom: 13,
      filter: [
        "all",
        ["in", "class", "B Road", "Paved", "secondary", "tertiary"]
      ],
      layout: {
        "symbol-placement": "line",
        "text-field": ["coalesce", ["get", "name1"], ""],
        "text-letter-spacing": 0.25,
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          13,
          9,
          16,
          16
        ]
      },
      paint: {
        "text-color": "hsla(0, 0%, 100%, 1)",
        "text-halo-color": "hsla(0, 0%, 0%, 0.72)",
        "text-halo-width": 2
      }
    },
    {
      name: "street" + sector + "labels",
      type: "symbol",
      source: sector,
      group: "details",
      minzoom: 14,
      filter: [
        "all",
        [
          "in",
          "class",
          "Not Classified",
          "Unknown",
          "Classified Unnumbered",
          "Unclassified",
          "Other Paved"
        ]
      ],
      layout: {
        "symbol-placement": "line",
        "text-field": ["coalesce", ["get", "name1"], ""],
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          14,
          9,
          16,
          13
        ]
      },
      paint: {
        "text-color": "hsla(0, 0%, 100%, 1)",
        "text-halo-color": "hsla(0, 0%, 0%, 0.72)",
        "text-halo-width": 2
      }
    }
  ];
}

const ADDITIONALLAYERSPREROAD = [
  {
    name: "land",
    source: "land",
    type: "fill",
    layout: {},
    popup: false,
    base: true,
    paint: {
      "fill-color": "#6a635b"
    }
  },
  {
    name: "urban-area",
    source: "urban-area",
    group: "details",
    minzoom: 4,
    popup: false,
    type: "fill",
    layout: {},
    base: true,
    paint: {
      "fill-color": "rgba(150,150,150,0.5)",
      "fill-opacity": 0.5,
      "fill-antialias": true
    }
  },
  {
    name: "water-smallscale",
    source: "water-smallscale",
    group: "details",
    minzoom: 4,
    popup: false,
    type: "fill",
    layout: {},
    paint: {
      "fill-color": "rgb(20, 50, 80)",
      "fill-opacity": 1,
      "fill-outline-color": "rgba(0, 50, 100, 0.5)"
    }
  },
  {
    name: "water",
    source: "water",
    group: "details",
    minzoom: 10,
    popup: false,
    type: "fill",
    layout: {},
    paint: {
      "fill-color": "rgb(20, 50, 80)",
      "fill-opacity": 1,
      "fill-antialias": true,
      "fill-outline-color": "rgba(0, 50, 100, 0.5)"
    }
  },
  {
    name: "water-major",
    popup: false,
    source: "water-major",
    group: "details",
    minzoom: 5,
    type: "line",
    layout: {
      "line-cap": "round"
    },
    paint: {
      "line-color": "rgb(20, 50, 80)",
      "line-opacity": 1,
      "line-width": ["interpolate", ["linear"], ["zoom"], 5, 0.5, 14, 10],
      "line-blur": 1
    }
  },
  {
    name: "water-minor",
    popup: false,
    source: "water-minor",
    group: "details",
    minzoom: 8,
    type: "line",
    layout: {
      "line-cap": "round"
    },
    paint: {
      "line-color": "rgb(20, 50, 80)",
      "line-opacity": 1,
      "line-width": ["interpolate", ["linear"], ["zoom"], 8, 1, 14, 8],
      "line-blur": 1
    }
  }
];

const ADDITIONALLAYERSPOSTROAD = [
  {
    name: "railway_case",
    popup: false,
    source: "railway",
    group: "details",
    minzoom: 10.5,
    type: "line",
    layout: {
      "line-cap": "butt"
    },
    ladCodeField: "lad_code",
    paint: {
      "line-color": "#000",
      //"line-gap-width": 1,
      "line-width": ["interpolate", ["exponential", 2], ["zoom"], 8, 0.5, 14, 5]
    }
  },
  {
    name: "railway",
    popup: false,
    source: "railway",
    group: "details",
    minzoom: 10.5,
    type: "line",
    layout: {
      "line-cap": "butt"
    },
    paint: {
      "line-color": "#bbb",
      //"line-gap-width": 1,
      "line-dasharray": [2, 3],
      "line-width": ["interpolate", ["exponential", 2], ["zoom"], 8, 0.2, 14, 3]
    }
  },
  {
    name: "lad",
    description: "Local Authority (2023)",
    idField: "lad_code",
    nameField: "lad_name",
    popup: true,
    table: [
      "lad23",
      "lad23",
      "lad23",
      "lad23",
      "lad23",
      "lad23",
      "ne_10m_admin_2_counties_lakes",
      "lad23",
      "lad23",
      "lad23",
      "lad23"
    ],
    idColumn: [
      "lad23cd",
      "lad23cd",
      "lad23cd",
      "lad23cd",
      "lad23cd",
      "lad23cd",
      "fips",
      "lad23cd",
      "lad23cd",
      "lad23cd",
      "lad23cd"
    ],
    makePolygonSQL: function(id, table, column) {
      return `SELECT the_geom FROM ${table} where ${column} = '${id}'`;
    },
    source: "lad",
    group: "lad",
    type: "fill",
    layout: {},
    paint: {
      "fill-opacity": 0
    }
  },
  {
    name: "lad-boundary",
    popup: false,
    source: "lad",
    group: "lad",
    type: "line",
    layout: { "line-cap": "round" },
    paint: {
      "line-color": "#fec",
      "line-opacity": 0.2,
      "line-width": ["interpolate", ["linear"], ["zoom"], 7, 0.5, 11, 4],
      "line-blur": 1
    }
  },

  {
    name: "combined",
    description: "Combined Authority",
    idField: "cauth19cd",
    nameField: "cauth19nm",
    popup: true,
    table: [
      "combinedauthorities",
      "combinedauthorities",
      "combinedauthorities",
      "combinedauthorities",
      "combinedauthorities",
      "combinedauthorities",
      null,
      "combinedauthorities",
      "combinedauthorities",
      "combinedauthorities",
      "combinedauthorities"
    ],
    idColumn: [
      "cauth19cd",
      "cauth19cd",
      "cauth19cd",
      "cauth19cd",
      "cauth19cd",
      "cauth19cd",
      null,
      "cauth19cd",
      "cauth19cd",
      "cauth19cd",
      "cauth19cd"
    ],
    makePolygonSQL: function(id, table, column) {
      return `SELECT the_geom FROM ${table} where ${column} = '${id}'`;
    },
    source: "combined",
    group: "combined",
    type: "fill",
    layout: {},
    paint: {
      "fill-opacity": 0
    }
  },
  {
    name: "combined-boundary",
    popup: false,
    source: "combined",
    group: "combined",
    layout: { "line-cap": "round" },
    type: "line",
    paint: {
      "line-color": "#000000",
      "line-width": 5,
      "line-blur": 1
    }
  },
  {
    name: "pcon",
    description: "Constituency (2024)",
    idField: "pcon25cd",
    nameField: "pcon25nm_mod",
    popup: true,
    table: [
      "pcon25_uk",
      "pcon25_uk",
      "pcon25_uk",
      "pcon25_uk",
      "pcon25_uk",
      "pcon25_uk",
      null,
      "pcon25_uk",
      "pcon25_uk",
      "pcon25_uk",
      "pcon25_uk"
    ],
    idColumn: [
      "pcon25cd",
      "pcon25cd",
      "pcon25cd",
      "pcon25cd",
      "pcon25cd",
      "pcon25cd",
      null,
      "pcon25cd",
      "pcon25cd",
      "pcon25cd",
      "pcon25cd"
    ],
    makePolygonSQL: function(id, table, column) {
      return `SELECT the_geom FROM ${table} where ${column} = '${id}'`;
    },
    source: "pcon",
    group: "pcon",
    type: "fill",
    layout: {},
    paint: {
      "fill-opacity": 0
    }
  },
  {
    name: "pcon-boundary",
    popup: false,
    source: "pcon",
    group: "pcon",
    layout: { "line-cap": "round" },
    type: "line",
    paint: {
      "line-color": "#cccccc",
      "line-width": ["interpolate", ["linear"], ["zoom"], 7, 0.5, 11, 4],
      "line-blur": 1
    }
  },
  {
    name: "region",
    description: "Region",
    idField: "regioncode",
    nameField: "regionname",
    table: [
      "region",
      "region",
      "region",
      "region",
      "region",
      "region",
      "ne_10m_admin_1_states_provinces_lakes",
      "region",
      "region",
      "region",
      "region"
    ],
    idColumn: [
      "regioncode",
      "regioncode",
      "regioncode",
      "regioncode",
      "regioncode",
      "regioncode",
      "iso_3166_2",
      "regioncode",
      "regioncode",
      "regioncode",
      "regioncode"
    ],
    makePolygonSQL: function(id, table, column) {
      return `SELECT the_geom FROM ${table} where ${column} = '${id}'`;
    },
    popup: true,
    source: "region",
    group: "region",
    type: "fill",
    layout: {},
    paint: {
      "fill-opacity": 0
    }
  },
  {
    name: "region-boundary",
    source: "region",
    group: "region",
    popup: false,
    type: "line",
    layout: { "line-cap": "round" },
    paint: {
      "line-color": "#111111",
      "line-opacity": 0.3,
      "line-width": ["interpolate", ["linear"], ["zoom"], 4, 1.5, 12, 6],
      "line-blur": 1
    }
  },
  {
    name: "country",
    description: "Country",
    popup: false,
    source: "country",
    group: "land",
    type: "line",
    layout: { "line-cap": "round" },
    paint: {
      "line-color": "#111111",
      "line-opacity": 0.6,
      "line-width": ["interpolate", ["linear"], ["zoom"], 4, 2, 12, 12],
      "line-blur": 1
    }
  },
  {
    name: "retail",
    description: "Retail Centre",
    idField: "rc_id",
    nameField: "name_pretty",
    popup: true,
    table: [
      "retailcentreboundaries",
      "retailcentreboundaries",
      "retailcentreboundaries",
      "retailcentreboundaries",
      "retailcentreboundaries",
      "retailcentreboundaries",
      null,
      "retailcentreboundaries",
      "retailcentreboundaries",
      "retailcentreboundaries",
      "retailcentreboundaries"
    ],
    idColumn: ["rc_id", "rc_id", "rc_id", "rc_id", "rc_id", "rc_id", null],
    makePolygonSQL: function(id, table, column) {
      return `SELECT the_geom FROM ${table} where ${column} = '${id}'`;
    },
    source: "retail",
    group: "retail",
    type: "fill",
    layout: {},
    paint: {
      "fill-color": "#fff",
      "fill-opacity": 0.4
    }
  },
  {
    name: "retail-boundary",
    popup: false,
    group: "retail",
    source: "retail",
    type: "line",
    layout: { "line-cap": "round" },
    paint: {
      "line-color": "#4B0082",
      "line-width": 2.5
    }
  },
  {
    name: "place-0",
    popup: false,
    source: "place",
    group: "name",
    label: true,
    type: "symbol",
    minzoom: 4.5,
    maxzoom: 6,
    filter: ["all", [">=", "most_detail", 150000]],
    ladCodeField: "lad_code",
    layout: {
      "text-field": ["get", "name1"],

      "text-size": [
        "match",
        ["get", "local_type"],
        ["City", "Admin-1 capital", "Admin-0 capital"],
        11,
        "Town",
        9,
        "Village",
        8,
        8
      ]
    },
    paint: {
      "text-halo-color": "hsla(0, 0%, 100%, 0.72)",
      "text-halo-width": 2
    }
  },
  {
    name: "place",
    popup: false,
    source: "place",
    group: "name",
    label: true,
    type: "symbol",
    minzoom: 6,
    maxzoom: 7.5,
    filter: ["all", [">=", "most_detail", 72000]],
    ladCodeField: "lad_code",
    layout: {
      "text-field": ["get", "name1"],

      "text-size": [
        "match",
        ["get", "local_type"],
        ["City", "Admin-1 capital", "Admin-0 capital"],
        15,
        "Town",
        11,
        "Village",
        10,
        9
      ]
    },
    paint: {
      "text-halo-color": "hsla(0, 0%, 100%, 0.72)",
      "text-halo-width": 2
    }
  },
  {
    name: "place-2",
    popup: false,
    source: "place",
    group: "name",
    label: true,
    type: "symbol",
    maxzoom: 8.5,
    minzoom: 7.5,
    filter: ["all", [">=", "most_detail", 68000]],
    ladCodeField: "lad_code",
    layout: {
      "text-field": ["get", "name1"],

      "text-size": [
        "match",
        ["get", "local_type"],
        ["City", "Admin-1 capital", "Admin-0 capital"],
        18,
        "Town",
        16,
        "Village",
        14,
        12
      ]
    },
    paint: {
      "text-halo-color": "hsla(0, 0%, 100%, 0.72)",
      "text-halo-width": 2
    }
  },
  {
    name: "place-3",
    popup: false,
    source: "place",
    group: "name",
    label: true,
    type: "symbol",
    maxzoom: 9.5,
    minzoom: 8.5,
    filter: ["all", [">=", "most_detail", 51000]],
    ladCodeField: "lad_code",
    layout: {
      "text-field": ["get", "name1"],

      "text-size": [
        "match",
        ["get", "local_type"],
        ["City", "Admin-1 capital", "Admin-0 capital"],
        18,
        "Town",
        16,
        "Village",
        14,
        12
      ]
    },
    paint: {
      "text-halo-color": "hsla(0, 0%, 100%, 0.72)",
      "text-halo-width": 2
    }
  },
  {
    name: "place-4",
    popup: false,
    source: "place",
    group: "name",
    label: true,
    type: "symbol",
    maxzoom: 11,
    minzoom: 9.5,
    filter: ["all", [">=", "most_detail", 34000]],
    ladCodeField: "lad_code",
    layout: {
      "text-field": ["get", "name1"],
      "text-size": [
        "match",
        ["get", "local_type"],
        ["City", "Admin-1 capital", "Admin-0 capital"],
        18,
        "Town",
        16,
        "Village",
        14,
        12
      ]
    },
    paint: {
      "text-halo-color": "hsla(0, 0%, 100%, 0.72)",
      "text-halo-width": 2
    }
  },
  {
    name: "place-5",
    popup: false,
    source: "place",
    group: "name",
    label: true,
    type: "symbol",
    maxzoom: 12,
    minzoom: 11,
    filter: ["all", [">=", "most_detail", 17000]],
    ladCodeField: "lad_code",
    layout: {
      "text-field": ["get", "name1"],
      "text-size": [
        "match",
        ["get", "local_type"],
        ["City", "Admin-1 capital", "Admin-0 capital"],
        24,
        "Town",
        21,
        "Village",
        16,
        14
      ]
    },
    paint: {
      "text-halo-color": "hsla(0, 0%, 100%, 1.0)",
      "text-halo-width": 2
    }
  },
  {
    name: "place-6",
    popup: false,
    source: "place",
    group: "name",
    label: true,
    type: "symbol",
    minzoom: 12,
    ladCodeField: "lad_code",
    layout: {
      "text-field": ["get", "name1"],
      "text-size": [
        "match",
        ["get", "local_type"],
        ["City", "Admin-1 capital", "Admin-0 capital"],
        24,
        "Town",
        21,
        "Village",
        12,
        12
      ],
      "text-letter-spacing": [
        "match",
        ["get", "local_type"],
        ["City", "Admin-1 capital", "Admin-0 capital"],
        0.4,
        "Town",
        0.2,
        "Village",
        0,
        0
      ]
    },
    paint: {
      "text-halo-color": "hsla(0, 0%, 100%, 1.0)",
      "text-halo-width": 2
    }
  },
  {
    name: "lad_name",
    popup: false,
    source: "lad_point",
    group: "lad",
    minzoom: 8,
    maxzoom: 12,
    label: true,
    type: "symbol",
    layout: {
      "text-field": ["upcase", ["get", "lad_name"]],
      //"text-field": ["get", "lad_name"],
      //"text-size": 12,
      "text-size": ["interpolate", ["exponential", 2], ["zoom"], 8, 11, 13, 36],
      "text-letter-spacing": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        7,
        0.0,
        14,
        4
      ],
      "text-max-width": 4
    },
    paint: {
      "text-halo-color": "#444444",
      "text-color": "#ffffff",
      "text-halo-width": 1.5
    }
  },
  {
    name: "combined_name",
    popup: false,
    source: "combined_point",
    group: "combined",
    label: true,
    type: "symbol",
    layout: {
      "text-field": ["upcase", ["get", "cauth19nm"]],
      "text-size": 16
    },
    paint: {
      "text-halo-color": "#525252",
      "text-color": "#ffffff",
      "text-halo-width": 2
    }
  },
  {
    name: "pcon_name",
    popup: false,
    source: "pcon_point",
    group: "pcon",
    label: true,
    type: "symbol",
    layout: {
      "text-field": ["upcase", ["get", "pcon25nm_mod"]], 
      "text-size": ["interpolate", ["exponential", 2], ["zoom"], 8, 8, 13, 36],
      "text-letter-spacing": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        8,
        0.0,
        15,
        3
      ],
      "text-max-width": 8
    },
    paint: {
      "text-halo-color": "#444444",
      "text-color": "#ffffff",
      "text-halo-width": 2
    }
  },
  {
    name: "region_name",
    popup: false,
    source: "region_point",
    group: "region",
    minzoom: 4,
    maxzoom: 10,
    label: true,
    type: "symbol",
    layout: {
      "text-field": ["upcase", ["get", "regionname"]],
      "text-size": ["interpolate", ["linear"], ["zoom"], 4, 9, 11, 60],
      "text-letter-spacing": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        4,
        0.0,
        10,
        1.5
      ]
    },
    paint: {
      "text-halo-color": "#383838",
      "text-color": "#ffffff",
      "text-opacity": 1.0,
      "text-halo-width": 2
    }
  }
];

//ADDITIONALLAYERSint.concat(roadLayers("osod_roads_se"));
//ADDITIONALLAYERSint.push(roadLayers("osod_roads_sw"));
//ADDITIONALLAYERSint.push(roadLayers("osod_roads_nw"));
//ADDITIONALLAYERSint.push(roadLayers("osod_roads_nn"));

//console.log(ADDITIONALLAYERSint);

export const ADDITIONALLAYERS = ADDITIONALLAYERSPREROAD.concat(
  roadLayers("osod_roads_se")
)
  .concat(roadLayers("osod_roads_sw"))
  .concat(roadLayers("osod_roads_nw"))
  .concat(roadLayers("osod_roads_nn"))
  .concat(ADDITIONALLAYERSPOSTROAD);
