export const DATASOURCES = [
  {
    name: "water",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator from vmd_water where regionname <> 'Northern Ireland'",
      "select the_geom_webmercator from vmd_water where regionname = 'London'",
      "select the_geom_webmercator from vmd_water",
      "select the_geom_webmercator from vmd_water where regionname <> 'Northern Ireland' and regionname  <> 'Scotland'",
      "select the_geom_webmercator from vmd_water",
      "select the_geom_webmercator from vmd_water",
      "select the_geom_webmercator from ne_10m_lakes_north_america",
      "select the_geom_webmercator from vmd_water where regionname <> 'Northern Ireland' and regionname  <> 'Scotland'",
      "select the_geom_webmercator from vmd_water where regionname = 'London'",
      "select the_geom_webmercator from vmd_water",
      "select the_geom_webmercator from vmd_water"
    ]
  },
  {
    name: "water-smallscale",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select the_geom_webmercator from ne_10m_lakes",
      "select 0",
      "select 0",
      "select 0",
      "select 0"
    ]
  },
  {
    name: "water-major",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select the_geom_webmercator from ne_10m_rivers_lake_centerlines",
      "select 0",
      "select 0",
      "select 0",
      "select 0"
    ]
  },
  {
    name: "water-minor",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select the_geom_webmercator from ne_10m_rivers_north_america",
      "select 0",
      "select 0",
      "select 0",
      "select 0"
    ]
  },
  {
    name: "osod_roads_se",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, name1, class from osod_roads_se",
      "select the_geom_webmercator, distname as name1, class from vmd_road",
      "select the_geom_webmercator, name1, class from osod_roads_se",
      "select the_geom_webmercator, name1, class from osod_roads_se",
      "select the_geom_webmercator, name1, class from osod_roads_se",
      "select the_geom_webmercator, name1, class from osod_roads_se",
      //"select the_geom_webmercator, type as class from ne_10m_roads_north_america where country = 'United States'"
      "select the_geom_webmercator, '' as name1, highway as class from us_mainroads_3857",
      "select the_geom_webmercator, name1, class from osod_roads_se",
      "select the_geom_webmercator, distname as name1, class from vmd_road",
      "select the_geom_webmercator, name1, class from osod_roads_se",
      "select the_geom_webmercator, name1, class from osod_roads_se"
    ]
  },
  {
    name: "osod_roads_sw",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, name1, class from osod_roads_sw",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_sw",
      "select the_geom_webmercator, name1, class from osod_roads_sw",
      "select the_geom_webmercator, name1, class from osod_roads_sw",
      "select the_geom_webmercator, name1, class from osod_roads_sw",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_sw",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_sw",
      "select the_geom_webmercator, name1, class from osod_roads_sw"
    ]
  },
  {
    name: "osod_roads_nw",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, name1, class from osod_roads_nw",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_nw",
      "select the_geom_webmercator, name1, class from osod_roads_nw",
      "select the_geom_webmercator, name1, class from osod_roads_nw",
      "select the_geom_webmercator, name1, class from osod_roads_nw",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_nw",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_nw",
      "select the_geom_webmercator, name1, class from osod_roads_nw"
    ]
  },
  {
    name: "osod_roads_nn",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, name1, class from osod_roads_nn",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_nn",
      "select the_geom_webmercator, name1, class from osod_roads_nn",
      "select the_geom_webmercator, name1, class from osod_roads_nn",
      "select the_geom_webmercator, name1, class from osod_roads_nn",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_nn",
      "select 0",
      "select the_geom_webmercator, name1, class from osod_roads_nn",
      "select the_geom_webmercator, name1, class from osod_roads_nn"
    ]
  },
  {
    name: "railway",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from tl_2022_us_rails",
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from vmd_railway",
      "select the_geom_webmercator from vmd_railway"
    ]
  },
  {
    name: "land",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from ne_10m_land",
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from lad23",
      "select the_geom_webmercator from lad23"
    ]
  },
  {
    name: "urban-area",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select the_geom_webmercator, name as lad_code from us_c10_urban",
      "select 0",
      "select 0",
      "select 0",
      "select 0"
    ]
  },
  {
    name: "country",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select 0",
      "select the_geom_webmercator from ne_10m_admin_0_boundary_lines_land where adm0_a3_l = 'USA' or adm0_a3_r = 'USA'",
      "select 0",
      "select 0",
      "select 0",
      "select 0"
    ]
  },
  {
    name: "region",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, regionname, regioncode from region where regionname <> 'Northern Ireland'",
      "select the_geom_webmercator, regionname, regioncode from region where regionname = 'London'",
      "select the_geom_webmercator, regionname, regioncode from region",
      "select the_geom_webmercator, regionname, regioncode from region where regionname <> 'Northern Ireland' and regionname  <> 'Scotland'",
      "select the_geom_webmercator, regionname, regioncode from region",
      "select the_geom_webmercator, regionname, regioncode from region",
      "select the_geom_webmercator, name as regionname, iso_3166_2 as regioncode from ne_10m_admin_1_states_provinces_lakes where iso_a2 = 'US'",
      "select the_geom_webmercator, regionname, regioncode from region where regionname <> 'Northern Ireland' and regionname  <> 'Scotland'",
      "select the_geom_webmercator, regionname, regioncode from region where regionname = 'London'",
      "select the_geom_webmercator, regionname, regioncode from region",
      "select the_geom_webmercator, regionname, regioncode from region where regionname <> 'Northern Ireland'",
    ]
  },
  {
    name: "region_point",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select * from region_point where regionname <> 'Northern Ireland'",
      "select * from region_point where regionname = 'London'",
      "select * from region_point",
      "select * from region_point where regionname <> 'Northern Ireland' and regionname  <> 'Scotland'",
      "select * from region_point",
      "select * from region_point",
      "select the_geom_webmercator, name as regionname from ne_10m_admin_1_states_provinces_lakes_centroids where iso_a2 = 'US'",
      "select * from region_point where regionname <> 'Northern Ireland' and regionname  <> 'Scotland'",
      "select * from region_point where regionname = 'London'",
      "select * from region_point",
      "select * from region_point where regionname <> 'Northern Ireland'",
    ]
  },
  {
    name: "combined",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select * from combinedauthorities",
      "select * from combinedauthorities",
      "select * from combinedauthorities",
      "select * from combinedauthorities",
      "select * from combinedauthorities",
      "select * from combinedauthorities",
      "select 0",
      "select * from combinedauthorities",
      "select * from combinedauthorities",
      "select * from combinedauthorities",
      "select * from combinedauthorities"
    ]
  },
  {
    name: "combined_point",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select * from combinedauthorities_point",
      "select * from combinedauthorities_point",
      "select * from combinedauthorities_point",
      "select * from combinedauthorities_point",
      "select * from combinedauthorities_point",
      "select * from combinedauthorities_point",
      "select 0",
      "select * from combinedauthorities_point",
      "select * from combinedauthorities_point",
      "select * from combinedauthorities_point",
      "select * from combinedauthorities_point"
    ]
  },
  {
    name: "pcon",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select * from pcon25_uk",
      "select * from pcon25_uk",
      "select * from pcon25_uk",
      "select * from pcon25_uk",
      "select * from pcon25_uk",
      "select * from pcon25_uk",
      "select 0",
      "select * from pcon25_uk",
      "select * from pcon25_uk",
      "select * from pcon25_uk",
      "select * from pcon25_uk"
    ]
  },
  {
    name: "pcon_point",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select 0",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point",
      "select replace(pcon25nm, ' and ', ' & ') as pcon25nm_mod, * from pcon25_uk_point"
    ]
  },
  {
    name: "lad",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23 where rgn23nm <> 'Northern Ireland'",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23 where rgn23nm = 'London'",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23 where rgn23nm <> 'Northern Ireland' and rgn23nm <> 'Scotland'",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23",
      "select the_geom_webmercator, name as lad_name, fips as lad_code from ne_10m_admin_2_counties_lakes where iso_a2 = 'US'",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23 where rgn23nm <> 'Northern Ireland' and rgn23nm <> 'Scotland'",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23 where rgn23nm = 'London'",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23",
      "select the_geom_webmercator, lad23nm as lad_name, lad23cd as lad_code from lad23 where rgn23nm <> 'Northern Ireland'",
    ]
  },
  {
    name: "lad_point",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point where rgn23nm <> 'Northern Ireland'",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point where rgn23nm = 'London'",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point where rgn23nm <> 'Northern Ireland' and rgn23nm <> 'Scotland'",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point",
      "select 0",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point where rgn23nm <> 'Northern Ireland' and rgn23nm <> 'Scotland'",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point where rgn23nm = 'London'",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point",
      "select the_geom_webmercator, lad23nm as lad_name from lad23_point where rgn23nm <> 'Northern Ireland'",
    ]
  },
  {
    name: "retail",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select 0",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries",
      "select the_geom_webmercator, rc_id, name_pretty from retailcentreboundaries"
    ]
  },
  {
    name: "place",
    url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
    sql: [
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23 where rgn23nm <> 'Northern Ireland'",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23 where rgn23nm='London'",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23 where rgn23nm <> 'Northern Ireland' and rgn23nm <> 'Scotland'",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23",
      "select the_geom_webmercator, name as name1, featurecla as local_type, 600000/(scalerank+1) as most_detail, iso_a2 as lad_code from ne_10m_populated_places where iso_a2 = 'US'",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23 where rgn23nm <> 'Northern Ireland' and rgn23nm <> 'Scotland'",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23 where rgn23nm = 'London'",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23",
      "select the_geom_webmercator, name1, local_type, most_detai as most_detail, lad23cd as lad_code from on_populatedplace23 where rgn23nm <> 'Northern Ireland'",
    ]
  }
];
